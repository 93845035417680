.ant-menu-horizontal .ant-menu-item .ant-menu-title-content,
.ant-menu-horizontal .ant-menu-submenu .ant-menu-title-content {
  @apply relative !top-1;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:focus,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:active {
  @apply bg-gray-darkest;
}

.ant-menu-dark .ant-menu-sub {
  background: #001628 !important;
}

.ant-menu-dark.ant-menu-horizontal > .custom-menu-item.ant-menu-item {
  @apply flex items-center space-x-1 h-full;
}

.ant-tree-node-selected {
  @apply bg-white text-blue;
}
.ant-table .ant-table-container::after,
.ant-table .ant-table-container::before {
  @apply !shadow-none;
}
.ant-table [vt] > table > .ant-table-tbody > tr > td {
  @apply p-4;
}

.ant-card-head-wrapper {
  @apply flex-col sm:flex-row;
}

/* .ant-typography-copy {
  @apply relative top-[-3px];
} */

.ant-btn .anticon,
.ant-message .anticon {
  @apply relative -top-0.5 !important;
}
.ant-collapse .ant-collapse-item .ant-collapse-header {
  @apply pl-0;
}

.ant-collapse-content .ant-collapse-content-box {
  @apply p-0;
}

.province-collapse .ant-collapse .ant-collapse-item .ant-collapse-header {
  @apply px-3;
}

.province-collapse .ant-collapse-content .ant-collapse-content-box {
  @apply p-4;
}

.consolidated-steps .ant-steps-item-content {
  @apply w-40;
}
.ant-carousel .slick-dots li {
  @apply bg-[#8bf4da];
}
.ant-carousel .slick-dots li.slick-active button {
  @apply bg-[#35b091];
}

.custom-height .ant-cascader-menu {
  @apply h-full;
}
.custom-preview .ant-image-preview-img {
  @apply inline-block
}